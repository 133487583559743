import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2463 1673 c2 -5 8 -72 11 -150 l7 -143 -53 0 c-60 0 -52 -8 -125 120
l-42 75 6 -103 6 -102 -36 0 -35 0 -7 93 c-4 50 -5 117 -3 147 l3 55 47 3 47
3 53 -91 53 -92 3 91 c2 75 6 90 20 94 28 8 41 7 45 0z m-384 -178 c46 -69 82
-127 80 -129 -2 -1 -20 -8 -38 -15 -32 -10 -37 -9 -55 13 -20 24 -23 25 -63
14 -38 -11 -43 -16 -43 -40 0 -21 -6 -31 -26 -38 -60 -23 -60 -25 -53 140 3
83 8 152 10 154 9 7 65 24 84 25 15 1 42 -32 104 -124z m864 93 c20 -10 37
-21 37 -25 0 -4 -10 -17 -21 -29 -20 -21 -23 -22 -44 -8 -34 22 -83 21 -106
-3 -29 -29 -37 -98 -14 -123 15 -17 65 -29 65 -16 0 2 3 11 6 19 3 10 -4 17
-21 21 -23 6 -26 10 -20 34 4 15 8 28 10 30 2 2 31 -4 65 -14 46 -13 60 -21
55 -32 -3 -8 -13 -44 -22 -79 l-16 -65 -56 7 c-112 14 -161 60 -161 152 0 133
115 195 243 131z m227 -105 c0 -8 -21 -54 -46 -104 -25 -50 -44 -92 -42 -94 2
-1 22 -12 46 -24 44 -23 50 -36 29 -65 -12 -17 -18 -16 -95 23 -45 23 -82 43
-82 45 0 7 120 252 126 259 10 9 64 -25 64 -40z m-1450 -8 c42 -22 80 -92 80
-148 0 -37 -6 -53 -29 -79 -27 -30 -137 -96 -145 -87 -9 10 -126 248 -126 256
0 15 131 73 163 73 15 0 41 -7 57 -15z m-266 -210 c69 -84 80 -108 71 -152
-12 -66 -122 -131 -174 -104 -20 11 -161 170 -161 182 0 3 13 15 30 27 l29 22
67 -80 c38 -47 73 -80 84 -80 21 0 50 25 50 44 0 13 -55 90 -97 136 -27 30
-28 38 -6 62 27 30 45 20 107 -57z m2016 53 c81 -56 98 -131 46 -206 -60 -86
-137 -104 -211 -47 -69 52 -83 120 -38 191 51 80 138 106 203 62z m248 -233
c46 -50 72 -96 72 -127 0 -34 -45 -71 -77 -63 -20 5 -23 2 -23 -24 0 -36 -34
-71 -67 -71 -40 0 -77 26 -123 85 l-43 56 39 33 c21 18 68 58 104 89 36 31 70
56 76 56 6 1 25 -15 42 -34z m-2574 -12 c13 -27 13 -29 -23 -59 -26 -24 -33
-35 -25 -44 8 -8 23 -5 58 10 26 11 58 20 70 20 31 0 72 -34 80 -64 8 -34 -20
-101 -56 -131 -35 -30 -48 -31 -79 -6 l-23 18 37 24 c69 45 59 96 -11 60 -56
-29 -93 -27 -126 5 -32 33 -34 76 -5 119 17 24 75 75 86 75 1 0 9 -12 17 -27z
m-175 -237 c11 -21 10 -26 -13 -49 -53 -53 -23 -76 42 -32 45 30 90 32 126 4
33 -26 36 -88 5 -138 -39 -64 -57 -73 -93 -46 -18 14 -18 16 13 50 32 37 41
75 18 75 -7 0 -28 -11 -46 -24 -59 -44 -113 -42 -148 3 -27 35 -9 107 39 159
27 28 41 28 57 -2z m2980 -81 c28 -53 35 -39 -84 -163 -38 -40 -74 -80 -78
-88 -13 -23 -32 -16 -49 20 -15 31 -15 34 3 53 19 19 19 19 -3 61 -21 38 -26
41 -53 36 -27 -5 -32 -2 -48 31 -9 19 -17 37 -17 39 0 1 46 11 103 21 56 9
116 21 132 25 59 16 69 12 94 -35z m-1958 -168 c-6 -8 -8 -17 -4 -20 3 -4 0
-7 -8 -7 -8 0 -20 -9 -26 -20 -11 -17 -10 -18 5 -13 9 4 -17 -27 -58 -68 -94
-92 -167 -202 -211 -317 -18 -48 -35 -79 -37 -69 -2 9 -8 15 -12 12 -18 -11
-21 42 -4 90 55 165 162 301 308 394 55 34 64 38 47 18z m501 -9 c8 -7 36 -63
61 -123 51 -122 147 -363 147 -369 0 -2 -11 -1 -24 1 -18 4 -29 17 -41 51 -10
26 -26 65 -36 87 -10 22 -45 108 -79 190 -33 83 -65 152 -70 153 -13 5 -2 22
14 22 7 0 19 -6 28 -12z m343 2 c-3 -5 0 -7 7 -5 40 16 210 -149 283 -272 48
-82 90 -203 81 -236 -3 -12 -12 -31 -21 -42 -14 -19 -15 -19 -8 -1 4 12 3 17
-4 13 -6 -4 -18 13 -27 41 -76 209 -155 323 -308 440 -41 30 -46 38 -31 44 10
3 14 7 10 7 -12 1 2 21 14 21 6 0 7 -4 4 -10z m-1773 -56 c4 -3 1 -21 -6 -42
-11 -31 -18 -38 -44 -40 -27 -3 -33 -9 -44 -44 -11 -37 -10 -41 11 -57 22 -17
23 -20 10 -54 -7 -21 -18 -37 -24 -37 -5 0 -62 40 -126 88 l-116 87 12 41 c20
67 13 65 173 63 81 0 150 -3 154 -5z m3005 -29 c6 -19 8 -37 5 -39 -4 -2 -52
-18 -107 -36 -55 -18 -101 -33 -102 -34 -1 -1 5 -26 13 -55 9 -29 14 -55 11
-57 -2 -3 -17 -6 -31 -7 -26 -2 -29 3 -56 86 -24 75 -26 89 -13 94 34 13 249
81 259 82 6 1 15 -15 21 -34z m-1723 -136 c-16 -32 -9 -48 20 -49 19 0 66 -54
66 -75 0 -21 -17 -45 -32 -45 -4 0 -8 17 -8 37 0 36 -31 83 -54 83 -24 0 -33
-45 -19 -93 17 -60 52 -87 110 -87 54 0 95 26 123 78 14 26 19 29 14 12 -16
-59 -79 -117 -138 -127 l-34 -5 24 -36 c13 -19 24 -47 24 -62 0 -31 -37 -91
-60 -97 -11 -3 -12 -2 -3 4 7 5 19 24 26 43 13 31 13 38 -3 65 -23 41 -61 61
-155 81 -44 10 -95 27 -112 38 -39 25 -67 78 -57 109 7 26 53 77 68 77 6 0 3
-7 -7 -14 -24 -18 -38 -71 -27 -100 10 -27 83 -60 153 -70 44 -7 63 3 37 19
-24 15 -29 74 -10 125 10 27 15 50 12 50 -4 0 -2 4 3 8 6 4 18 19 28 35 24 37
31 34 11 -4z m-232 -402 c2 -40 15 -56 63 -81 26 -14 27 -16 8 -16 -29 0 -107
61 -111 87 -3 18 25 59 34 51 2 -2 4 -21 6 -41z m-1172 -103 l0 -65 56 31 c30
18 57 31 58 29 6 -7 26 -48 26 -53 0 -2 -24 -12 -52 -22 -73 -23 -72 -21 -33
-55 28 -25 38 -28 54 -20 43 24 51 15 51 -54 0 -36 2 -65 5 -65 2 0 28 14 57
31 49 29 53 30 65 13 22 -29 11 -46 -37 -61 -25 -8 -52 -17 -60 -20 -11 -3 -1
-17 35 -49 l50 -44 -25 -20 -25 -20 -18 23 c-10 12 -28 35 -41 51 l-23 28 -23
-56 c-23 -59 -33 -65 -64 -41 -19 14 -19 16 12 59 18 24 32 49 32 54 0 5 -29
12 -65 15 -60 6 -78 3 -163 -28 -7 -3 5 -20 33 -46 25 -23 45 -44 45 -48 0 -3
-12 -14 -25 -24 l-24 -18 -33 51 c-18 27 -36 50 -39 50 -3 0 -16 -24 -29 -52
-13 -29 -25 -54 -26 -56 -3 -6 -54 22 -54 31 0 5 14 26 30 47 37 47 38 60 1
60 -16 0 -43 3 -60 6 -27 6 -30 9 -24 33 3 14 7 28 10 30 2 3 28 -3 59 -13 31
-10 57 -16 59 -14 2 2 0 21 -5 43 -17 72 -14 85 20 85 l30 0 0 -66 0 -65 56
32 c45 26 58 29 65 18 5 -8 9 -17 9 -21 0 -18 20 -6 20 12 0 24 5 24 60 5 62
-22 72 -20 64 13 -10 41 -14 45 -34 27 -18 -16 -20 -14 -52 34 -18 28 -36 51
-39 51 -3 0 -16 -24 -29 -52 -13 -29 -25 -54 -26 -56 -3 -6 -54 22 -54 31 0 5
14 26 30 47 37 47 38 60 1 60 -16 0 -43 3 -60 6 -27 6 -30 9 -24 33 3 14 7 28
10 30 2 3 28 -3 59 -13 31 -10 57 -16 59 -14 2 2 0 21 -5 43 -17 72 -14 85 20
85 l30 0 0 -66z m2950 -39 l0 -65 55 31 c31 17 58 30 60 28 2 -2 6 -16 10 -30
6 -23 2 -28 -27 -38 -18 -7 -44 -17 -58 -23 l-25 -10 45 -39 c28 -24 55 -39
73 -39 26 0 27 -1 27 -60 0 -69 5 -71 70 -34 42 24 45 24 57 8 22 -30 15 -42
-39 -60 -29 -9 -55 -18 -57 -21 -3 -2 15 -24 40 -49 l44 -45 -24 -18 -25 -19
-33 42 c-18 22 -33 44 -33 49 0 23 -19 0 -39 -48 -13 -30 -26 -55 -31 -55 -4
0 -18 8 -30 18 l-21 17 35 47 c20 26 36 49 36 52 0 3 -12 6 -27 6 -16 0 -44 3
-64 6 -31 5 -35 9 -31 30 6 35 16 37 73 19 28 -10 53 -15 56 -12 3 3 0 29 -7
57 -12 49 -13 50 -30 35 -18 -16 -21 -14 -55 34 -20 28 -38 51 -40 51 -2 0
-15 -25 -29 -55 -27 -59 -31 -62 -58 -41 -25 18 -23 24 18 76 l36 45 -63 8
c-35 4 -65 9 -67 11 -8 8 11 56 23 56 7 0 32 -7 55 -15 23 -8 46 -15 50 -15 4
0 2 27 -5 60 -7 32 -10 62 -7 65 3 3 18 5 34 5 28 0 28 0 28 -65z m-1563 49
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-80 -10 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m975 -98 c-2 -49 -18 -100 -64 -199
-27 -59 -34 -67 -44 -52 -11 14 -14 13 -32 -12 -11 -15 -21 -32 -22 -38 -3
-16 -13 -29 -35 -45 -20 -14 -20 -14 -1 7 28 33 90 173 121 272 24 75 31 88
46 84 15 -4 19 0 19 18 0 12 3 19 7 16 3 -4 5 -27 5 -51z m-1489 -225 c25 -51
25 -54 9 -76 l-17 -22 -27 34 c-32 41 -85 164 -94 222 -7 46 12 103 28 79 5
-9 8 -7 8 7 1 11 16 -27 34 -85 18 -58 44 -129 59 -159z m637 157 c0 -9 4 -8
11 2 8 11 9 2 4 -34 -4 -27 -9 -55 -12 -63 -7 -17 18 -12 71 13 36 18 36 18
36 -3 0 -14 -10 -19 -41 -21 -23 -1 -50 -7 -59 -12 -17 -9 -19 -4 -22 60 -2
39 0 70 5 70 4 0 7 -6 7 -12z m1360 -124 l0 -65 56 31 c30 18 57 31 58 29 6
-7 26 -48 26 -53 0 -3 -25 -13 -55 -22 -30 -10 -55 -21 -55 -25 0 -5 19 -26
43 -48 41 -39 42 -40 22 -55 -30 -23 -37 -20 -70 29 -16 24 -33 45 -36 45 -4
0 -17 -24 -29 -52 -12 -29 -23 -54 -25 -56 -4 -4 -55 21 -55 27 0 3 16 28 35
56 20 27 32 51 28 52 -4 1 -34 4 -66 8 -56 6 -57 7 -51 33 4 15 8 29 11 31 2
3 28 -3 59 -13 31 -10 57 -16 59 -14 2 2 0 21 -5 43 -17 72 -14 85 20 85 l30
0 0 -66z m-1405 28 c29 -10 28 -10 -17 -11 -51 -1 -51 -1 -42 23 4 11 10 13
18 8 6 -5 25 -14 41 -20z m118 -118 c-6 -16 -23 -9 -23 9 0 7 -9 23 -20 37
-33 42 -22 47 14 6 19 -21 32 -45 29 -52z m-106 32 c-16 -33 -25 -42 -34 -34
-10 8 -6 18 15 44 37 47 45 43 19 -10z m765 -29 c-7 -8 -9 -16 -6 -20 10 -10
-57 -100 -126 -167 -100 -99 -187 -145 -392 -210 -16 -4 -30 -4 -33 0 -3 5 13
14 35 21 22 7 40 15 40 19 0 4 14 13 30 20 17 7 30 16 30 20 0 5 8 6 19 3 10
-3 22 0 25 6 5 7 0 9 -11 5 -10 -3 2 6 25 20 24 14 49 23 55 19 6 -3 7 -1 3 5
-4 7 -2 12 4 12 6 0 26 11 44 25 18 14 41 25 50 25 9 0 15 4 12 9 -6 9 27 43
40 40 5 0 19 2 32 6 18 6 20 10 11 22 -18 21 4 41 26 23 11 -9 15 -9 15 -1 0
7 5 9 10 6 6 -3 10 -1 10 5 0 7 9 25 21 41 11 16 18 29 14 29 -3 0 -1 9 5 21
7 11 14 18 17 15 3 -3 1 -12 -5 -19z m-824 -63 c0 -63 2 -68 38 -106 32 -32
36 -40 24 -48 -12 -7 -20 1 -38 35 -12 25 -26 45 -31 45 -5 0 -19 -20 -31 -45
-12 -25 -25 -45 -29 -45 -21 0 -17 16 11 51 32 40 37 67 25 137 -6 37 -5 42
12 42 16 0 18 -8 19 -66z m230 16 c-6 -56 0 -60 57 -36 23 10 37 11 41 4 9
-15 -16 -28 -54 -28 -40 0 -52 -11 -83 -73 -19 -36 -27 -44 -39 -37 -10 7 -11
13 -5 18 16 11 55 64 55 73 0 5 -10 9 -22 9 -13 0 -29 4 -36 8 -7 5 -38 5 -70
1 -31 -4 -58 -6 -60 -4 -2 2 10 11 27 20 33 16 95 15 145 -5 l26 -10 -6 55
c-6 48 -5 55 11 55 15 0 17 -7 13 -50z m-261 -63 c4 -5 -16 -7 -45 -6 -28 2
-52 4 -52 5 0 1 3 9 6 17 4 12 12 13 44 3 21 -6 42 -15 47 -19z m715 -29 c-7
-7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-412 -29 c16 -17 27
-35 24 -40 -10 -16 -19 -9 -42 31 -27 48 -22 51 18 9z m-433 -129 c28 -10 95
-28 148 -39 92 -19 128 -31 92 -31 -10 0 -17 -4 -17 -10 0 -5 -7 -10 -16 -10
-8 0 -13 -4 -10 -8 6 -10 -54 -9 -109 2 -22 5 -74 24 -115 44 -62 29 -71 36
-55 43 11 4 16 8 12 8 -10 1 1 21 11 21 4 0 30 -9 59 -20z m-814 -137 c18 -21
16 -41 -23 -198 -5 -22 -10 -44 -10 -48 0 -6 113 120 137 152 5 6 53 -41 53
-52 0 -11 -188 -230 -202 -236 -6 -2 -27 13 -48 34 l-37 37 18 81 c10 45 21
98 25 117 l6 35 -38 -45 c-22 -25 -53 -60 -70 -79 l-31 -34 -26 19 c-15 10
-26 24 -24 31 1 6 46 63 101 127 l98 116 27 -19 c14 -10 34 -28 44 -38z m2312
12 c5 -26 2 -32 -27 -45 -21 -9 -34 -23 -36 -37 -4 -27 5 -28 72 -8 68 21 73
20 113 -20 31 -31 35 -40 30 -71 -8 -47 -49 -99 -96 -124 l-38 -19 -28 29 -27
29 37 15 c63 27 98 83 49 80 -10 -1 -44 -8 -75 -15 -54 -12 -58 -12 -87 12
-65 51 -48 126 43 187 52 35 61 33 70 -13z m-219 -209 l-6 -85 85 -46 c47 -26
85 -50 85 -54 0 -4 -17 -17 -39 -29 l-39 -22 -58 35 c-54 33 -59 34 -62 17 -2
-11 6 -35 17 -53 27 -44 26 -53 -6 -70 -16 -7 -31 -15 -34 -17 -8 -3 -169 251
-169 267 0 10 51 41 68 41 5 0 20 -20 33 -45 13 -25 27 -45 31 -45 5 0 8 17 9
38 1 76 13 112 44 132 17 11 34 20 39 20 5 0 6 -36 2 -84z m-1770 -37 c41 -22
74 -46 74 -53 0 -7 -7 -21 -15 -32 -14 -19 -15 -19 -55 4 -33 19 -44 21 -56
11 -8 -6 -14 -15 -14 -20 0 -8 52 -44 74 -52 5 -2 1 -17 -8 -35 l-17 -32 -40
21 c-32 16 -44 18 -55 8 -22 -18 -17 -37 14 -53 53 -30 54 -31 41 -59 -7 -15
-18 -27 -26 -27 -8 0 -47 19 -88 43 l-73 43 75 137 c42 75 80 137 86 137 5 0
43 -18 83 -41z m235 -105 c57 -20 108 -40 113 -45 6 -5 5 -19 -2 -35 -11 -23
-16 -25 -51 -19 -32 5 -39 3 -44 -12 -3 -10 -19 -56 -36 -103 -17 -47 -33 -90
-36 -97 -3 -9 -12 -10 -37 -1 -18 6 -36 14 -41 18 -6 5 30 124 68 221 5 13 -2
20 -29 29 -20 6 -36 15 -36 20 0 9 22 60 25 60 2 0 49 -16 106 -36z m1240 -3
c18 -14 23 -29 23 -62 0 -24 -3 -51 -8 -61 -7 -15 9 -30 108 -101 18 -12 16
-14 -28 -31 l-46 -18 -51 41 c-73 58 -80 56 -54 -19 8 -23 15 -46 15 -49 0
-12 -72 -34 -79 -25 -4 5 -20 45 -36 89 -15 44 -39 106 -52 139 -13 32 -21 62
-16 66 4 4 33 17 63 28 71 27 131 28 161 3z m-1009 -111 c4 -25 11 -79 15
-120 l8 -75 35 110 36 110 41 3 c47 4 47 3 58 -123 4 -44 10 -85 14 -92 3 -7
21 35 39 93 l33 104 39 0 c21 0 41 -3 43 -7 4 -7 -99 -299 -109 -312 -1 -1
-25 0 -52 4 l-50 7 -6 56 c-4 31 -10 77 -13 101 l-6 45 -32 -95 c-34 -101 -27
-95 -105 -91 -33 2 -28 -14 -58 200 l-19 133 41 -3 c40 -3 41 -4 48 -48z m683
7 c57 -57 73 -167 32 -228 -62 -93 -219 -92 -267 2 -30 57 -35 136 -13 179 29
56 75 80 150 80 60 0 67 -2 98 -33z"/>
<path d="M1960 3992 c0 -49 2 -53 22 -50 31 4 31 17 3 63 l-24 40 -1 -53z"/>
<path d="M1618 3913 c-10 -2 -18 -9 -18 -14 0 -19 65 -139 76 -139 6 0 19 9
29 20 25 27 14 88 -20 118 -26 22 -34 24 -67 15z"/>
<path d="M3351 3723 c-34 -41 -39 -73 -15 -97 34 -34 90 -12 118 48 39 82 -43
122 -103 49z"/>
<path d="M3659 3504 c-11 -14 -10 -18 6 -30 32 -24 52 -11 40 26 -8 24 -28 26
-46 4z"/>
<path d="M3575 3434 c-15 -11 -17 -18 -9 -32 14 -22 52 -28 60 -8 6 15 -12 56
-24 56 -4 0 -16 -7 -27 -16z"/>
<path d="M3823 3209 c-36 -10 -39 -17 -20 -41 11 -15 16 -13 51 18 42 36 34
42 -31 23z"/>
<path d="M1631 2614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M800 2957 c0 -3 21 -17 46 -32 37 -22 48 -25 55 -14 14 22 10 39 -8
39 -10 0 -35 3 -55 6 -21 4 -38 4 -38 1z"/>
<path d="M2650 1630 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M2984 1137 c-3 -8 0 -26 5 -41 9 -23 14 -25 30 -16 28 15 33 27 21
50 -12 23 -48 27 -56 7z"/>
<path d="M2626 1044 c-21 -21 -21 -112 0 -142 11 -16 25 -22 53 -22 33 0 40 4
51 30 14 34 7 93 -16 128 -17 26 -65 30 -88 6z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
